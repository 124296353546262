import React, { useState, useEffect } from 'react';
import { TonConnectButton } from '@tonconnect/ui-react';
import styled from 'styled-components';

const AppContainer = styled.div`
  font-family: 'Roboto', Arial, sans-serif;
  margin: 0;
  padding: 20px;
  background: linear-gradient(135deg, #e0c097, #674609);
  color: #000000;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Category = styled.div`
  margin-bottom: 20px;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

// Обновленный стиль для Dish
const Dish = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px 0;
  border-bottom: 1px solid #cccccc;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
`;

// Новый стиль для контейнера цены и кнопки/счетчика
const PriceActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

// Обновленный стиль для AddButton
const AddButton = styled.button`
  background-color: #674609;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  min-width: 30px;
`;

// Новый стиль для счетчика
const Counter = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const SendOrderButton = styled.button`
  position: fixed;
  bottom: 20px;
  left: 20px;
  right: 20px;
  background-color: #674609;
  color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  z-index: 1000;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
`;

const QuantityInput = styled.input`
  width: 40px;
  text-align: center;
  margin: 0 5px;
`;

function App() {
  const [menuData, setMenuData] = useState([]);
  const [cart, setCart] = useState({});
  const [message, setMessage] = useState('');

  useEffect(() => {
    // Инициализация Telegram Mini App
    const tg = window.Telegram.WebApp;
    tg.ready();

    fetch('https://menu-telegram-app.pages.dev/menu.json')
      .then(response => response.json())
      .then(data => setMenuData(data))
      .catch(error => console.error('Error loading menu:', error));
  }, []);

  const addToCart = (dish) => {
    setCart(prevCart => ({
      ...prevCart,
      [dish]: (prevCart[dish] || 0) + 1
    }));
  };

  const updateCartQuantity = (dish, quantity) => {
    setCart(prevCart => ({
      ...prevCart,
      [dish]: quantity
    }));
  };

  const removeFromCart = (dish) => {
    setCart(prevCart => {
      const newCart = { ...prevCart };
      delete newCart[dish];
      return newCart;
    });
  };

  const calculateTotal = () => {
    return Object.entries(cart).reduce((total, [dish, quantity]) => {
      const dishData = menuData.find(item => item.dish === dish);
      return total + (dishData ? dishData.price * quantity : 0);
    }, 0);
  };

  const sendOrder = () => {
    const orderDetails = Object.entries(cart).map(([dish, quantity]) => {
      const dishData = menuData.find(item => item.dish === dish);
      return `${dish}: ${quantity} x ${dishData.price} AR песо = ${quantity * dishData.price} AR песо`;
    }).join('\n');
    const total = calculateTotal();
    const orderMessage = `Новый заказ:\n\n${orderDetails}\n\nИтого: ${total} AR песо`;

    // Подтверждение перед отправкой заказа
    if (!window.confirm(`Вы уверены, что хотите отправить заказ?\n\n${orderMessage}`)) {
      return;
    }

    // Отправка данных в Telegram
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.sendData(orderMessage, (result) => {
        if (result.ok) {
          setMessage('Заказ успешно отправлен!');
          setCart({}); // Очистка корзины после успешной отправки
        } else {
          setMessage('Ошибка при отправке заказа. Пожалуйста, попробуйте снова.');
        }
      });
    } else {
      console.error('Telegram WebApp is not available');
      setMessage('Telegram WebApp не доступен.');
    }
  };

  const groupByCategory = (menu) => {
    return menu.reduce((acc, item) => {
      (acc[item.category] = acc[item.category] || []).push(item);
      return acc;
    }, {});
  };

  const groupedMenu = groupByCategory(menuData);
  const totalItems = Object.values(cart).reduce((sum, quantity) => sum + quantity, 0);
  const totalSum = calculateTotal();

  return (
    <AppContainer>
      <Header>
        <h1>Домашняя кухня МАР-ДЕЛЬ-ПЛАТА</h1>
        <TonConnectButton />
      </Header>
      {Object.entries(groupedMenu).map(([category, dishes]) => (
        <Category key={category}>
          <h2>{category}</h2>
          {dishes.map(dish => (
            <Dish key={dish.dish}>
              <span>{dish.dish}</span>
              <PriceActionContainer>
                <span>{dish.price.toLocaleString('es-AR')} AR песо</span>
                {cart[dish.dish] ? (
                  <Counter>
                    <AddButton onClick={() => updateCartQuantity(dish.dish, Math.max(0, (cart[dish.dish] || 0) - 1))}>-</AddButton>
                    <span>{cart[dish.dish]}</span>
                    <AddButton onClick={() => addToCart(dish.dish)}>+</AddButton>
                  </Counter>
                ) : (
                  <AddButton onClick={() => addToCart(dish.dish)}>+</AddButton>
                )}
              </PriceActionContainer>
            </Dish>
          ))}
        </Category>
      ))}
      {message && <div>{message}</div>}
      <SendOrderButton onClick={sendOrder}>
        Отправить заказ ({totalItems} шт, {totalSum.toLocaleString('es-AR')} AR песо)
      </SendOrderButton>
    </AppContainer>
  );
}

export default App;